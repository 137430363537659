import { camelCase, forEach, snakeCase } from 'lodash';
import moment from 'moment';

export const generateId = (): string => {
    return Math.random().toString(36).slice(2);
};

export const toCamelCase = (str: string): string => {
    return camelCase(str);
};

export const toSnakeCase = (str: string): string => {
    return snakeCase(str);
};

export const dateTimeFormat = (
    dateTime: string,
    format: string = 'DD/MM/YYYY HH:mm:ss'
): string => {
    try {
        const value = new Date(dateTime);
        return moment(value).format(format);
    } catch (error) {
        return '--';
    }
};
export const dateFormat = (date: string, format: string = 'DD/MM/YYYY') => {
    try {
        return date ? moment(date).format(format) : '--';
    } catch (error) {
        return '--';
    }
};

export const escapeForRegex = (str?: string) => {
    return String(str).replace(/[.*+?^=!:${}()|[\]/\\]/g, '\\$&');
};

export const numberFormatter = (value: any) => {
    return value?.toLocaleString();
};

const formatClassName = (className: string) => {
    return className.replace(/([A-Z])/g, '-$1').toLowerCase();
};

export const cls = (
    ...classes: Array<
        string | undefined | boolean | { [key: string]: boolean | undefined }
    >
) => {
    const classList = classes.reduce((acc: string[], cur) => {
        if (typeof cur === 'string') {
            acc.push(cur);
        } else if (typeof cur === 'object') {
            forEach(cur, (value, key) => {
                if (value) {
                    acc.push(formatClassName(key));
                }
            });
        }
        return acc;
    }, [] as string[]);
    return classList.join(' ');
};
