const routeConstants = {
    LOGIN: '/login',
    LOGOUT: '/logout',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/forgot',
    MAIN: '/',
    ACTIVATE: '/activate',
    TWO_FACTOR: '/two-factor',
    SUMMARY: '/summary',
    JOBS: '/jobs',
    JOB_DETAIL: '/jobs/:uid',
    JOB_APPLY: '/jobs/:uid/apply',
    PROGRESS: '/progress',
    FILES: '/files',
    PAYMENT_HISTORY: '/payment-history',
    SIGN_UP: '/sign-up',
    UNSKILL_STEP: '/summary/unskill-step',
    QUESTIONNAIRE: '/summary/questionnaire',
    PERSONAL_INFO: '/summary/personal-info',
    PROFILE: '/profile/view',
    PROFILE_EDIT: '/profile/edit',
    CHANGE_PASSWORD: '/profile/change-password',
    INVOICES: '/invoices',
    ADMIN_JOB_DETAIL_ABSOLUTE: '/admin/jobs/:uid',
    ADMIN_JOB_DETAIL: '/admin/jobs/:uid/info',
    ADMIN_JOB_DETAIL_APPLIES: '/admin/jobs/:uid/applies',
    ADMIN: '/admin',
    USERS: '/admin/users',
    ADMIN_USERS: '/admin/users',
    ADMIN_JOBS: '/admin/jobs',
    ADMIN_FORMS: '/admin/forms',
    ADMIN_PROFILE: '/admin/profile/view',
    ADMIN_PROFILE_EDIT: '/admin/profile/edit',
    ADMIN_CHANGE_PASSWORD: '/admin/profile/change-password',
    ADMIN_LOGOUT: '/admin/logout',
    APPLY: '/admin/jobs/:uid/:email',
    USER: '/admin/users/:email',
    USER_BASIC: '/admin/users/:email/basic',
    USER_BASIC_INNER: '/admin/users/:email/basic/:part',
    USER_SUMMARY: '/admin/users/:email/summary',
    USER_JOB: '/admin/users/:email/job',
    USER_LABOR: '/admin/users/:email/labor',
    USER_PAYMENT: '/admin/users/:email/payment',
    ADMIN_JOB_CONTRACT: '/admin/users/:email/job/:cuid',
    CATEGORIES_SETTING: '/admin/jobs/categories',
};

export default routeConstants;
