import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FormState, FormDataStore } from 'reducers/form/type';
import { NUMBER_ITEMS_PER_PAGE } from 'utils/constants';

const initialState: FormState = {};

interface FormCaseReducer<P> extends CaseReducer<FormState, PayloadAction<P>> {}

const SET_ITEMS_TO_FORM_STEP_5: FormCaseReducer<{
    token: string;
    items: Array<{
        uid?: string;
        name: string;
        value: string;
        description: string;
    }>;
}> = (state, { payload: { items, token } }) => {
    const data: FormDataStore = {};
    let completedCount = 0;
    let lastItemCompleted = 0;
    items?.forEach((item, index) => {
        const { name, value, description, uid } = item;
        data[name] = {
            uid,
            value,
            description,
        };
        if ((value === '1' && description) || value === '0') {
            completedCount += 1;
            lastItemCompleted = index;
        }
    });

    return {
        [token]: {
            ...state[token],
            steps_5: {
                data,
                completedCount,
                totalPages: Math.round(items.length / NUMBER_ITEMS_PER_PAGE),
                currentPage:
                    state[token]?.steps_5?.currentPage ||
                    Math.ceil((lastItemCompleted + 1) / NUMBER_ITEMS_PER_PAGE),
                totalItems: items.length,
            },
        },
    };
};

const UPDATE_ITEM_TO_FORM_STEP_5: FormCaseReducer<{
    token: string;
    name: string;
    value: string;
    description: string;
}> = (state, { payload: { name, value, description, token } }) => {
    const currentItem = state[token].steps_5.data[name];
    const isCurrentItemCompleted =
        (currentItem?.value === '1' && currentItem?.description) ||
        currentItem?.value === '0';
    const isItemCompleted = (value === '1' && description) || value === '0';

    let completedCount = state[token].steps_5.completedCount;

    if (isCurrentItemCompleted && !isItemCompleted) {
        completedCount -= 1;
    } else if (!isCurrentItemCompleted && isItemCompleted) {
        completedCount += 1;
    }

    return {
        [token]: {
            ...state[token],
            steps_5: {
                ...state[token].steps_5,
                data: {
                    ...state[token].steps_5.data,
                    [name]: {
                        uid: currentItem?.uid,
                        value,
                        description,
                    },
                },
                completedCount,
            },
        },
    };
};

const SET_ITEMS_TO_FORM_STEP_4: FormCaseReducer<{
    token: string;
    items: Array<{
        uid?: string;
        name: string;
        value: string;
        description: string;
    }>;
}> = (state, { payload: { items, token } }) => {
    const data: FormDataStore = {};
    let completedCount = 0;
    let lastItemCompleted = 0;
    items?.forEach((item, index) => {
        const { name, value, uid } = item;
        data[name] = {
            uid,
            value,
        };
        if (value === '1') {
            completedCount += 1;
            lastItemCompleted = index;
        }
    });

    return {
        [token]: {
            ...state[token],
            steps_4: {
                data,
                completedCount,
                totalPages: items.length,
                currentPage:
                    state[token]?.steps_4?.currentPage || lastItemCompleted + 1,
                totalItems: items.length,
            },
        },
    };
};

const UPDATE_ITEM_TO_FORM_STEP_4: FormCaseReducer<{
    token: string;
    name: string;
    value: string;
}> = (state, { payload: { name, value, token } }) => {
    const currentItem = state[token].steps_4.data[name];
    const isCurrentItemCompleted = currentItem?.value === '1';
    const isItemCompleted = value === '1';
    const completedCount =
        state[token].steps_4.completedCount +
        Number(isItemCompleted) -
        Number(isCurrentItemCompleted);
    return {
        [token]: {
            ...state[token],
            steps_4: {
                ...state[token].steps_4,
                data: {
                    ...state[token].steps_4.data,
                    [name]: {
                        uid: currentItem?.uid,
                        value,
                    },
                },
                completedCount,
            },
        },
    };
};

const SET_CURRENT_PAGE: FormCaseReducer<{
    token: string;
    currentPage: number;
    formName: 'steps_4' | 'steps_5';
}> = (state, { payload: { currentPage, token, formName } }) => {
    return {
        [token]: {
            ...state[token],
            [formName]: {
                ...state[token][formName],
                currentPage,
            },
        },
    };
};

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        SET_ITEMS_TO_FORM_STEP_5,
        UPDATE_ITEM_TO_FORM_STEP_5,
        SET_ITEMS_TO_FORM_STEP_4,
        UPDATE_ITEM_TO_FORM_STEP_4,
        SET_CURRENT_PAGE,
    },
});

export default formSlice;
