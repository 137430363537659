// import Button from 'components/Input/Button';
import { memo } from 'react';
import { useTranslation } from 'translation';
import './index.scss';
import { DEFAULT_PATH } from 'utils/constants';
const E404 = memo(() => {
    const { translate } = useTranslation();
    return (
        <div className="page-404">
            <div className="page-404__logo">404</div>
            <div className="page-404__title">{translate('error.404')}</div>
            <a href={DEFAULT_PATH} className="page-404__btn">
                {translate('error.back-to-home')}
            </a>
        </div>
    );
});
E404.displayName = 'E404';

export default E404;
