import { getLanguage } from 'reducers/locale/function';
const lang = () => getLanguage();
const messageCodes = {
    SUCCESS: lang() === 'VI' ? 'Thành công' : 'Success',
    CONTACT_ADMIN_FOR_SUPPORT:
        lang() === 'VI'
            ? 'Có lỗi xảy ra. Vui lòng liên hệ quản trị viên để được hỗ trợ'
            : 'An error has occurred. Please contact the administrator for assistance',

    VALIDATION_ERROR:
        lang() === 'VI'
            ? 'Lỗi xác thực. Vui lòng kiểm tra lại thông tin'
            : 'Validation error has occured. Please check your information again',
    PARSE_ERROR:
        lang() === 'VI'
            ? 'Lỗi định dạng/ cú pháp. Vui lòng kiểm tra lại thông tin'
            : 'Parse error has occured. Please check your information again',
    AUTHENTICATION_FAILED:
        lang() === 'VI' ? 'Đăng nhập thất bại' : 'Login failed',
    NOT_AUTHENTICATED:
        lang() === 'VI' ? 'Bạn chưa đăng nhập' : `You haven't logged in`,
    NOT_FOUND: lang() === 'VI' ? 'Không tìm thấy dữ liệu' : 'Data not found',
    SORT_TYPE_NOT_SUPPORT:
        lang() === 'VI'
            ? 'Không thể sắp xếp theo loại này'
            : 'Cannot sort by this type',
    EXPIRED_SESSION:
        lang() === 'VI'
            ? 'Phiên đăng nhập đã hết hạn'
            : 'Your login session has expired',
    PAGE_NOT_FOUND:
        lang() === 'VI' ? 'Trang này hiện không khả dụng' : 'Page not found',
    METHOD_NOT_ALLOWED:
        lang() === 'VI'
            ? 'Phương thức không được chấp nhận'
            : 'This method is not allowed',

    SERVER_ERROR:
        lang() === 'VI'
            ? 'Có lỗi máy chủ xảy ra. Vui lòng thử lại sau'
            : 'Server error has occurred. Please try again later',
    PERMISSION_DENIED:
        lang() === 'VI'
            ? 'Quyền thực hiện chức năng này của bạn bị từ chối'
            : 'Insufficient permission',
    USER_DOES_NOT_HAVE_PERMISSIONS:
        lang() === 'VI'
            ? 'Bạn không có quyền thực hiện chức năng này'
            : 'You do not have the permission to perform this',

    MISSING_FIELD:
        lang() === 'VI'
            ? 'Còn trường dữ liệu bỏ trống'
            : 'Required field is empty',

    INVALID_FIELD:
        lang() === 'VI'
            ? 'Trường dữ liệu nhập vào không hợp lệ'
            : 'Field data is invalid',

    INVALID_ARGUMENTS:
        lang() === 'VI'
            ? 'Đối số truyền không hợp lệ'
            : 'Arguments are invalid',

    CURRENT_PASSWORD_NOT_MATCH:
        lang() === 'VI'
            ? 'Mật khẩu hiện tại không chính xác'
            : 'The password you entered is incorrect',
    USER_WITH_USERNAME_NOT_EXISTED:
        lang() === 'VI'
            ? 'Tài khoản không tồn tại hoặc đã bị khóa. Vui lòng liên hệ quản trị viên để được hỗ trợ'
            : 'The account does not exist or has been locked. Please contact the administrator for assistance',
    ACCOUNT_LOCKED_OR_DISABLE:
        lang() === 'VI'
            ? 'Tài khoản không tồn tại hoặc đã bị khóa. Vui lòng liên hệ quản trị viên để được hỗ trợ'
            : 'The account does not exist or has been locked. Please contact the administrator for assistance',

    SITE_NOT_FOUND:
        lang() === 'VI'
            ? 'Không tìm thấy trang web hoặc tài nguyên bạn yêu cầu'
            : 'Site could not be found',

    ROLE_NOT_FOUND:
        lang() === 'VI'
            ? 'Quyền truy cập của bạn hiện không khả dụng. Vui lòng liên hệ quản trị viên để được hỗ trợ'
            : 'Your access is currently unavailable. Please contact the administrator for assistance',
    USER_NOT_BELONG_THIS_SITE:
        lang() === 'VI'
            ? 'Tài khoản không thuộc trang web này'
            : 'The account does not belong to this website',

    NEED_LOGIN:
        lang() === 'VI'
            ? 'Bạn cần đăng nhập để thực hiện chức năng này'
            : 'You need to login to perform this',
    INVALID_OR_EXPIRED_TOKEN:
        lang() === 'VI'
            ? 'Phiên đăng nhập đã hết hạn'
            : 'Your login session has expired',
    MISSING_ACCESS_TOKEN:
        lang() === 'VI'
            ? 'Bạn cần đăng nhập để thực hiện chức năng này'
            : 'You need to login to perform this',
    INVALID_ACCOUNT:
        lang() === 'VI'
            ? 'Tài khoản không tồn tại hoặc đã bị khóa. Vui lòng liên hệ quản trị viên để được hỗ trợ'
            : 'The account does not exist or has been locked. Please contact the administrator for assistance',
    ACCOUNT_ACTIVATED:
        lang() === 'VI'
            ? 'Tài khoản của bạn đã được kích hoạt. Vui lòng đăng nhập'
            : 'Your account has been activated. Please login',
    NOT_FOUND_USER_DEVICE_TOKEN:
        lang() === 'VI'
            ? 'Phiên đăng nhập đã hết hạn'
            : 'Your login session has expired',
    USER_ACTIVATION_NOT_FOUND:
        lang() === 'VI'
            ? 'Mã kích hoạt không tồn tại'
            : 'User activation code is not found',
    NEED_ACTIVATED:
        lang() === 'VI'
            ? 'Tài khoản của bạn chưa được kích hoạt. Vui lòng kiểm tra email để kích hoạt tài khoản'
            : 'Your account has not been activated yet. Please check your email to activate your account',
    INVALID_USERNAME_OR_PASSWORD:
        lang() === 'VI'
            ? 'Tên đăng nhập hoặc mật khẩu không chính xác'
            : 'The username or password is invalid',

    SUBJECT_NOT_FOUND_BY_UID:
        lang() === 'VI'
            ? 'Nội dung bạn tìm kiếm không tồn tại'
            : 'The subject you are searching for does not exist',
    SUBJECT_LOCKED:
        lang() === 'VI'
            ? 'Nội dung bạn tìm kiếm đã tạm khóa'
            : 'The subject you are searching for is locked',
    SUBJECT_NOT_LOCKED:
        lang() === 'VI'
            ? 'Nội dung bạn tìm kiếm chưa bị khóa'
            : 'The subject you are searching for is not locked',
    SUBJECT_REMOVED:
        lang() === 'VI'
            ? 'Nội dung bạn tìm kiếm đã bị xóa'
            : 'The subject you are searching for has been removed',

    ATTACHMENT_NOT_FOUND:
        lang() === 'VI'
            ? 'Tài liệu đính kèm không tồn tại'
            : 'Attachment is not found',

    FORM_NOT_FOUND:
        lang() === 'VI' ? 'Biểu mẫu không tồn tại' : 'Form is not found',

    ITEM_VALUE_REQUIRED:
        lang() === 'VI'
            ? 'Vui lòng nhập đầy đủ thông tin'
            : 'Please provide complete information',
    ITEM_VALUE_INVALID:
        lang() === 'VI' ? 'Dữ liệu không hợp lệ' : 'Data is invalid',
    OBSERVATION_NOT_FOUND:
        lang() === 'VI' ? 'Không tìm thấy dữ liệu' : 'Observation is not found',
    NOT_SUPPORTED_ITEMS:
        lang() === 'VI'
            ? 'Không hỗ trợ các loại dữ liệu này'
            : 'These types of data are not supported',
    DUPLICATE_VALUE_FOR_ITEM:
        lang() === 'VI' ? 'Dữ liệu trùng lặp cho câu' : 'Data is duplicated.',
    DUPLICATE_VALUE_FOR_ITEM_WITH_ORDINAL:
        lang() === 'VI' ? 'Dữ liệu trùng lặp cho câu' : 'Data is duplicated.',
    OBSERVATION_IS_COMPLETED_OR_LOCKED:
        lang() === 'VI'
            ? 'Không thể sửa dữ liệu đã hoàn thành'
            : 'Cannot modify complete/locked observation',

    USER_DOES_NOT_HAVE_ROLES:
        lang() === 'VI'
            ? 'Bạn không có quyền truy cập'
            : 'You do not have the permission to access',
    INVALID_TWO_FACTOR_CODE:
        lang() === 'VI'
            ? 'Mã xác thực không hợp lệ'
            : 'The two-factor code is invalid',

    EMAIL_ALREADY_EXIST:
        lang() === 'VI' ? 'Email đã tồn tại' : 'This email already exists',
    THIS_FACEBOOK_ACCOUNT_ALREADY_EXIST:
        lang() === 'VI'
            ? 'Tài khoản Facebook đã tồn tại'
            : 'This facebook account is already in use',
    CATEGORY_IN_USED:
        lang() === 'VI'
            ? 'Danh mục đang được sử dụng'
            : 'This category is in used',
    CATEGORY_ALREADY_EXIST_WILL_NOT_REGENERATE:
        lang() === 'VI'
            ? 'Có danh mục đã tồn tại. Vui lòng tải lại trang'
            : 'There is category already exists. Please reload the page',
    CATEGORY_NOT_FOUND:
        lang() === 'VI'
            ? 'Danh mục đã bị xóa. Vui lòng tải lại trang'
            : 'Category is deleted. Please reload the page',
    default:
        lang() === 'VI'
            ? 'Có lỗi xảy ra... Vui lòng thử lại'
            : 'An error has occured... Please try again later',
} as {
    [key: string]: string;
};

export default messageCodes;
