import Loadable from 'react-loadable';
import LoadableLoading from 'route/components/LoadableLoading';
import routeConstants from 'route/routeConstant';

interface CommonRoute {
    name: string;
    path: string;
    authorization?: boolean;
    childRoutes?: Route[];
}

interface RedirectRoute extends CommonRoute {
    redirect: string;
}

interface ComponentRoute extends CommonRoute {
    Component: any;
}

type Route = RedirectRoute | ComponentRoute;

const commonLoadable = (loader: any) =>
    Loadable({
        loader,
        loading: LoadableLoading,
    });

const routes: Array<Route> = [
    {
        name: 'Login',
        path: routeConstants.LOGIN,
        Component: commonLoadable(() => import('views/Login')),
    },
    {
        name: 'SignUp',
        path: routeConstants.SIGN_UP,
        Component: commonLoadable(() => import('views/SignUp')),
    },
    {
        name: 'ForgotPassword',
        path: routeConstants.FORGOT_PASSWORD,
        Component: commonLoadable(() => import('views/ForgotPassword')),
    },
    {
        name: 'ResetPassword',
        path: routeConstants.RESET_PASSWORD,
        Component: commonLoadable(() => import('views/ResetPassword')),
    },
    {
        name: 'Activate',
        path: routeConstants.ACTIVATE,
        Component: commonLoadable(() => import('views/Activate')),
    },
    {
        name: 'MainLayout',
        path: routeConstants.MAIN,
        Component: commonLoadable(() => import('layouts/Main')),
        authorization: true,
        childRoutes: [
            {
                name: 'Main default',
                path: routeConstants.MAIN,
                redirect: routeConstants.SUMMARY,
            },
            {
                name: 'ProfileEdit',
                path: routeConstants.PROFILE_EDIT,
                Component: commonLoadable(() => import('views/Account/Edit')),
            },
            {
                name: 'ChangePassword',
                path: routeConstants.CHANGE_PASSWORD,
                Component: commonLoadable(
                    () => import('views/Account/ChangePassword')
                ),
            },
            {
                name: 'Profile',
                path: routeConstants.PROFILE,
                Component: commonLoadable(() => import('views/Account')),
            },
            {
                name: 'Logout',
                path: routeConstants.LOGOUT,
                Component: commonLoadable(() => import('views/Logout')),
            },
            {
                name: 'Summary',
                path: routeConstants.SUMMARY,
                Component: commonLoadable(() => import('views/Summary')),
            },
            {
                name: 'UnskillStep',
                path: routeConstants.UNSKILL_STEP,
                Component: commonLoadable(() => import('views/UnskillStep')),
            },
            {
                name: 'Questionnaire',
                path: routeConstants.QUESTIONNAIRE,
                Component: commonLoadable(() => import('views/Questionarie')),
            },
            {
                name: 'PersonalInfo',
                path: routeConstants.PERSONAL_INFO,
                Component: commonLoadable(() => import('views/Profile')),
            },
            {
                name: 'Jobs',
                path: routeConstants.JOBS,
                Component: commonLoadable(() => import('views/Jobs')),
            },
            {
                name: 'JobDetail',
                path: routeConstants.JOB_DETAIL,
                Component: commonLoadable(() => import('views/JobDetail')),
            },
            {
                name: 'Files',
                path: routeConstants.FILES,
                Component: commonLoadable(() => import('views/Files')),
            },
            {
                name: 'Inovices',
                path: routeConstants.INVOICES,
                Component: commonLoadable(() => import('views/Invoices')),
            },
        ],
    },
    {
        name: 'AdminLayout',
        path: routeConstants.ADMIN,
        Component: commonLoadable(() => import('layouts/Admin')),
        authorization: true,
        childRoutes: [
            {
                name: 'Admin default',
                path: routeConstants.ADMIN,
                redirect: routeConstants.USERS,
            },
            {
                name: 'AdminProfileEdit',
                path: routeConstants.ADMIN_PROFILE_EDIT,
                Component: commonLoadable(() => import('views/Account/Edit')),
            },
            {
                name: 'AdminChangePassword',
                path: routeConstants.ADMIN_CHANGE_PASSWORD,
                Component: commonLoadable(
                    () => import('views/Account/ChangePassword')
                ),
            },
            {
                name: 'Profile',
                path: routeConstants.ADMIN_PROFILE,
                Component: commonLoadable(() => import('views/Account')),
            },
            {
                name: 'Logout',
                path: routeConstants.ADMIN_LOGOUT,
                Component: commonLoadable(() => import('views/Logout')),
            },
            {
                name: 'Users',
                path: routeConstants.USERS,
                Component: commonLoadable(() => import('views/Users')),
            },
            {
                name: 'JobDetail',
                path: routeConstants.ADMIN_JOB_DETAIL_ABSOLUTE,
                authorization: true,
                Component: commonLoadable(() => import('views/AdminJobDetail')),
                childRoutes: [
                    {
                        name: 'JobDetail default',
                        path: routeConstants.ADMIN_JOB_DETAIL,
                        Component: commonLoadable(
                            () => import('views/AdminJobDetail/Info')
                        ),
                    },
                    {
                        name: 'JobDetail Applies',
                        path: routeConstants.ADMIN_JOB_DETAIL_APPLIES,
                        Component: commonLoadable(
                            () => import('views/AdminJobDetail/Applies')
                        ),
                    },
                ],
            },
            {
                name: 'Jobs',
                path: routeConstants.ADMIN_JOBS,
                Component: commonLoadable(() => import('views/AdminJobs')),
            },
            {
                name: 'Forms',
                path: routeConstants.ADMIN_FORMS,
                Component: commonLoadable(() => import('views/AdminForms')),
            },
            {
                name: 'Categories Setting',
                path: routeConstants.CATEGORIES_SETTING,
                Component: commonLoadable(
                    () => import('views/CategoriesSetting')
                ),
            },
        ],
    },
    {
        name: 'UserLayout',
        path: routeConstants.USER,
        Component: commonLoadable(() => import('layouts/Users')),
        authorization: true,
        childRoutes: [
            {
                name: 'User default',
                path: routeConstants.USER,
                redirect: routeConstants.USER_SUMMARY,
            },
            {
                name: 'Summary',
                path: routeConstants.USER_SUMMARY,
                Component: commonLoadable(
                    () => import('views/UserDetail/Summary')
                ),
            },
            {
                name: 'Job&Contract',
                path: routeConstants.USER_JOB,
                Component: commonLoadable(() => import('views/UserDetail/job')),
            },
            {
                name: 'Payment',
                path: routeConstants.USER_PAYMENT,
                Component: commonLoadable(
                    () => import('views/UserDetail/payment')
                ),
            },
            {
                name: 'Basic information',
                path: routeConstants.USER_BASIC,
                Component: commonLoadable(
                    () => import('views/UserDetail/profile')
                ),
                childRoutes: [
                    {
                        name: 'User basic default',
                        path: routeConstants.USER_BASIC,
                        redirect: routeConstants.USER_BASIC_INNER,
                    },
                    {
                        name: 'User basic',
                        path: routeConstants.USER_BASIC_INNER,
                        Component: commonLoadable(
                            () => import('views/UserDetail/profile')
                        ),
                    },
                ],
            },
        ],
    },

    {
        name: 'JobApply',
        path: routeConstants.JOB_APPLY,
        authorization: true,
        Component: commonLoadable(() => import('views/JobApply')),
    },
    {
        name: '404',
        path: '*',
        Component: commonLoadable(() => import('views/Page/404')),
    },
];

export default routes;
