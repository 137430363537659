import Message from 'components/Message';
import ApiBase from 'modules/apis/apiBase';
import messageCodes from 'modules/apis/messageCode';
import { IResponse } from 'modules/apis/Response';

const _OAUTH_PATH = '/oauth';
const _USER_PATH = '/users';
const _SYSTEM_CONFIG = '/systemconfig';

class UserService extends ApiBase {
    signUp = (requestBody: any) => {
        const url = `${_OAUTH_PATH}/register`;
        return this.post(url, requestBody);
    };

    login = (requestBody: any) => {
        const url = `${_OAUTH_PATH}/login`;
        const res = this.post(url, requestBody);
        return res;
    };

    logout = () => {
        const url = `${_OAUTH_PATH}/logout`;
        const res = this.post(url);
        return res;
    };

    me = () => {
        const url = `${_USER_PATH}/get/me`;
        return this.get(url);
    };

    update = (requestBody: any) => {
        const url = `${_USER_PATH}/update/profile`;
        return this.put(url, requestBody);
    };

    updateMe = (requestBody: any) => {
        const url = `${_USER_PATH}/update/me`;
        return this.put(url, requestBody);
    };

    updatePassword = (requestBody: any) => {
        const url = `${_USER_PATH}/password/update`;
        return this.put(url, requestBody);
    };

    forgotPassword = (email: string) => {
        const url = `${_OAUTH_PATH}/password/forgot`;
        return this.post(url, { email });
    };

    resetPassword = (token: string, password: string) => {
        const url = `${_OAUTH_PATH}/password/reset`;
        return this.post(url, { token, password });
    };

    twoFactor = (requestBody: any) => {
        const url = `${_OAUTH_PATH}/login/2fa`;
        const handleResponse = (response: IResponse) => {
            if (response.errorCode === 400) {
                Message.sendError(
                    messageCodes[response.messageCode] || messageCodes.default
                );
                return { ...response, isSuccess: true };
            }
            return response;
        };
        return this.post(url, requestBody, {}, handleResponse);
    };

    activateAccount = (requestBody: {
        token: string | null;
        password: any;
    }) => {
        const url = `${_OAUTH_PATH}/activate`;
        const handleResponse = (response: IResponse) => {
            if ([404, 401].includes(response.errorCode)) {
                Message.sendError(
                    messageCodes[response.messageCode] || messageCodes.default
                );
                return { ...response, isSuccess: true };
            }
            return response;
        };
        return this.post(url, requestBody, {}, handleResponse);
    };

    getPasswordConfig = () => {
        const url = `${_SYSTEM_CONFIG}/password/rules`;
        return this.get(url);
    };

    getRoleDetail = async (roleName: string) => {
        const url = `/roles/detail/${roleName}`;
        const res = await this.get(url);
        return res.isSuccess
            ? {
                  ...res,
                  data: {
                      ...res.data,
                      subjectAuditLog: res.data.subjectauditlog || [], // backend return subjectauditlog instead of subjectAuditLog
                  },
              }
            : res;
    };

    fbLogin = (requestBody: any) => {
        const url = `${_OAUTH_PATH}/facebook/login`;
        return this.post(url, requestBody);
    };
}

const userService = new UserService();

export default userService;
