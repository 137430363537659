import _toNumber from 'lodash/toNumber';
import moment from 'moment';
import routeConstants from 'route/routeConstant';

export const DATA_SEPARATOR = ',';

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_DISPLAY_DATE_FORMAT = 'DD/MM/YYYY';

export const CURRENT_YEAR = _toNumber(moment().format('YYYY'));

export const NUMBER_ITEMS_PER_PAGE = 10;

export const MAX_TAGS_DISPLAY = 3;
export const DEFAULT_PATH = routeConstants.MAIN;

export const MAX_CATEGORIES_SELECT = 5;
